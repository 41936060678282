import { ReactNode } from 'react'

import { DrawerButtonLink, DrawerButtonLinkProps } from '@sikt/sds-drawer'
import { LinkProps } from 'next/link'

import { TypedLinkWrapper } from '@/components/TypedLinkWrapper/TypedLinkWrapper'
import { ConditionalWrapper } from '@/features/personer/GroupSearch/utils/ConditionalWrapper'
import { RouteHref } from '@/types/routes'
import { FeatureFlag, FeatureFlagBase } from '@/utils/featureFlags/FeatureFlag'

import styles from './MenuItem.module.css'

interface MenuItemProps extends Omit<DrawerButtonLinkProps, 'href'> {
  href?: RouteHref
  icon: ReactNode
  nextLinkProps?: Omit<LinkProps, 'href'>
  children: ReactNode
  expandable?: boolean
  isExpanded?: boolean
  onToggleExpand?: () => void
  subMenuItems?: MenuItemType[]
  expandedMenus?: Record<string, boolean>
  toggleMenu?: (title: string) => void
}

interface BaseMenuItem {
  title: string
  children?: MenuItemType[]
  featureFlag?: FeatureFlagBase
}

export type MenuItemType =
  | (BaseMenuItem & { href: RouteHref; children?: never })
  | (BaseMenuItem & { href?: never; children: MenuItemType[] })

export function MenuItem({
  href,
  icon,
  children,
  expandable = false,
  isExpanded = false,
  onToggleExpand,
  nextLinkProps,
  subMenuItems,
  expandedMenus = {},
  toggleMenu,
}: MenuItemProps) {
  const renderSubMenu = () => {
    if (!subMenuItems || !toggleMenu) return null

    return subMenuItems.map((item) => {
      const isExpandable = !!item.children?.length
      const isItemExpanded = expandedMenus[item.title] ?? false
      return (
        <div key={item.title} className={styles.submenuItem}>
          <ConditionalWrapper
            condition={Boolean(item.featureFlag)}
            wrapper={(children: JSX.Element) => (
              <FeatureFlag
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                flag={item.featureFlag!.flag}
                environmentsOverride={item.featureFlag?.environmentsOverride}
                doNotFetchFlags
              >
                {children}
              </FeatureFlag>
            )}
          >
            <MenuItem
              href={isExpandable ? undefined : item.href}
              icon={undefined}
              expandable={isExpandable}
              isExpanded={isItemExpanded}
              onToggleExpand={() => {
                toggleMenu(item.title)
              }}
              subMenuItems={item.children}
              expandedMenus={expandedMenus}
              toggleMenu={toggleMenu}
            >
              {item.title}
            </MenuItem>
          </ConditionalWrapper>
        </div>
      )
    })
  }

  if (expandable) {
    return (
      <div>
        <DrawerButtonLink className={styles.menuItem} icon={icon} onClick={onToggleExpand}>
          {children}
        </DrawerButtonLink>
        {isExpanded && renderSubMenu()}
      </div>
    )
  }

  if (href) {
    return (
      <TypedLinkWrapper legacyBehavior passHref href={href} {...nextLinkProps}>
        <DrawerButtonLink className={styles.menuItem} icon={icon}>
          {children}
        </DrawerButtonLink>
      </TypedLinkWrapper>
    )
  }

  return (
    <DrawerButtonLink className={styles.menuItem} icon={icon}>
      {children}
    </DrawerButtonLink>
  )
}
