'use client'

import { useEffect } from 'react'

import { fetchFeatureFlags } from './unleashServerAction'
import { flagsStore } from '@/utils/featureFlags/useTypedFlag'

export function useFeatureFlagFetcher() {
  const { setFlagStore } = flagsStore()

  useEffect(() => {
    async function getFetchFeatureFlags() {
      const flags = await fetchFeatureFlags()
      setFlagStore(flags)
    }

    getFetchFeatureFlags().catch((error) => {
      console.error('Error fetching feature flags', error)
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}
