import { UserCircleIcon } from '@sikt/sds-icons'
import { useSession } from 'next-auth/react'

import { Flex } from '@/components/Flex/Flex'
import { useUserContext } from '@/lib/auth/userContext'

import styles from './UserInfo.module.css'

export function UserInfo() {
  const { userInfo } = useUserContext()
  const session = useSession()

  return (
    <Flex alignItems="center" gap="medium">
      {userInfo ? (
        <Flex direction="column">
          <span className={styles.username}>{`${userInfo.fornavn} ${userInfo.etternavn}`}</span>
          <span className={styles.userSubtitle}>{`${userInfo.rollekode}, ${userInfo.navn}`}</span>
        </Flex>
      ) : (
        <span>{session.data?.user?.name}</span>
      )}
      <UserCircleIcon fontSize={32} />
    </Flex>
  )
}
