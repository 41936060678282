'use client'

import { ApolloLink, HttpLink } from '@apollo/client'
import {
  ApolloClient,
  ApolloNextAppProvider,
  InMemoryCache,
  SSRMultipartLink,
} from '@apollo/experimental-nextjs-app-support'

import { cacheConfig } from './cacheConfig'
import { getAuthLink } from '../getAuthLink'
import { WithChildren } from '@/types/genericReactProps'

interface MakeClientProps {
  sessionTokenValue: string | undefined
  sudoFnr: string | undefined
  sudoOrgnr: string | undefined
  appBaseUrl: string | undefined
}

function makeClient({ sessionTokenValue, sudoFnr, sudoOrgnr, appBaseUrl }: MakeClientProps) {
  const authLink = getAuthLink({ sessionTokenValue, fnr: sudoFnr, orgnr: sudoOrgnr })
  const httpLink = new HttpLink({
    uri: `${appBaseUrl ?? ''}/api/graphql`,
  })

  const httpLinkWithAuth = authLink ? authLink.concat(httpLink) : httpLink

  return new ApolloClient({
    cache: new InMemoryCache(cacheConfig),
    link:
      typeof window === 'undefined'
        ? ApolloLink.from([
            new SSRMultipartLink({
              stripDefer: true,
            }),
            httpLinkWithAuth,
          ])
        : httpLinkWithAuth,
    credentials: 'same-origin',
  })
}

interface ApolloWrapperProps extends WithChildren {
  sessionTokenValue: string | undefined
  sudoFnr: string | undefined
  sudoOrgnr: string | undefined
}

export function ApolloWrapper({
  children,
  sessionTokenValue,
  sudoFnr,
  sudoOrgnr,
}: ApolloWrapperProps) {
  const appBaseUrl = process.env.NEXT_PUBLIC_APP_BASE_URL

  return (
    <ApolloNextAppProvider
      makeClient={() =>
        makeClient({
          sessionTokenValue,
          sudoFnr,
          sudoOrgnr,
          appBaseUrl: appBaseUrl ?? undefined,
        })
      }
    >
      {children}
    </ApolloNextAppProvider>
  )
}
