'use client'

import { useEffect } from 'react'

import { Button } from '@sikt/sds-button'
import { useTranslations } from 'next-intl'

import { PageArrangement } from '@/components/PageArrangement/PageArrangement'

export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string }
  reset: () => void
}) {
  const t = useTranslations('common.Error')

  // TODO: SB-623 log error
  useEffect(() => {
    console.error(error)
  }, [error])

  return (
    <PageArrangement title={t('pageTitle')} enableBack>
      <p>{t('body')}</p>
      <p>{error.message}</p>
      <Button
        variant="strong"
        onClick={() => {
          reset()
        }}
      >
        {t('retry')}
      </Button>
    </PageArrangement>
  )
}
