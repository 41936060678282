'use client'

import { useState } from 'react'

import { useTranslations } from 'next-intl'

import { MenuItemDivider } from './MenuItemDivider'
import { Flex } from '@/components/Flex/Flex'
import type { MenuItemType } from '@/components/Header/Menu/MenuItem'
import { MenuItem } from '@/components/Header/Menu/MenuItem'
import { ConditionalWrapper } from '@/features/personer/GroupSearch/utils/ConditionalWrapper'
import { FeatureFlag } from '@/utils/featureFlags/FeatureFlag'
import { useFeatureFlagFetcher } from '@/utils/featureFlags/useFeatureFlagFetcher'

export const Menu = () => {
  const t = useTranslations('components.AppDrawer')
  const [expandedMenuItems, setExpandedMenuItems] = useState<Record<string, boolean>>({})
  useFeatureFlagFetcher()

  const menuItems: MenuItemType[] = [
    { href: '/', title: t('home') },
    {
      title: t('opptak'),
      featureFlag: {
        flag: 'opptak-menu-parent',
        environmentsOverride: {
          inDevelopment: true,
          inReview: true,
          inTest: true,
        },
      },
      children: [
        {
          href: '/opptak',
          title: t('opprettOgRediger'),
          featureFlag: {
            flag: 'opptak',
            environmentsOverride: {
              inDevelopment: true,
              inReview: true,
              inTest: true,
            },
          },
        },
        {
          href: '/opptakskjoring',
          title: t('opptakskjoring'),
          featureFlag: {
            flag: 'opptakskjoring',
            environmentsOverride: {
              inDevelopment: true,
              inReview: true,
              inTest: true,
            },
          },
        },
      ],
    },
    {
      href: '/regelverk',
      title: t('regelverk'),
      featureFlag: {
        flag: 'regelverk',
        environmentsOverride: {
          inDevelopment: true,
          inReview: true,
          inTest: true,
        },
      },
    },
    {
      href: '/soknadsbehandling',
      title: t('soknadsbehandling'),
      featureFlag: {
        flag: 'soknadsbehandling',
        environmentsOverride: {
          inDevelopment: true,
          inReview: true,
          inTest: true,
        },
      },
    },
    {
      href: '/person',
      title: t('personer'),
    },
    {
      href: '/person/gruppe',
      title: t('groupSearch'),
    },
  ]

  const toggleMenu = (title: string) => {
    setExpandedMenuItems((prev) => ({
      ...prev,
      [title]: !prev[title],
    }))
  }

  return (
    <Flex direction="column">
      {menuItems.map((item) => (
        <ConditionalWrapper
          key={item.title}
          condition={Boolean(item.featureFlag)}
          wrapper={(children: JSX.Element) => (
            <FeatureFlag
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              flag={item.featureFlag!.flag}
              environmentsOverride={item.featureFlag?.environmentsOverride}
              doNotFetchFlags
            >
              {children}
            </FeatureFlag>
          )}
        >
          <MenuItem
            key={item.title}
            icon={undefined}
            href={item.href}
            expandable={!!item.children?.length}
            isExpanded={expandedMenuItems[item.title]}
            onToggleExpand={() => {
              toggleMenu(item.title)
            }}
            subMenuItems={item.children}
            expandedMenus={expandedMenuItems}
            toggleMenu={toggleMenu}
          >
            {item.title}
          </MenuItem>
        </ConditionalWrapper>
      ))}
      <MenuItemDivider />
    </Flex>
  )
}
