import { useEffect, useState } from 'react'

import { Badge } from '@sikt/sds-badge'
import { Heading2, Paragraph } from '@sikt/sds-core'
import { ArrowRightIcon } from '@sikt/sds-icons'
import { useTranslations } from 'next-intl'

import { CommandGroup } from './useCommands'
import { Flex } from '../Flex/Flex'

import styles from './CommandGroupComponent.module.css'

interface CommandGroupProps {
  group: string
  commandGroup: CommandGroup
  filterValue: string
  onClosePalette: () => void
}

export function CommandGroupComponent({
  group,
  commandGroup,
  filterValue,
  onClosePalette,
}: CommandGroupProps) {
  const { commands } = commandGroup
  const t = useTranslations('components.CommandPalette.CommandGroup')
  const [isMacOS, setIsMacOS] = useState(false)

  useEffect(() => {
    if (typeof navigator !== 'undefined') {
      setIsMacOS(/Macintosh|Mac OS X/.test(navigator.userAgent))
    }
  }, [])

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.shiftKey) {
        commands.forEach((command) => {
          if (event.key === command.shortcut) {
            command.action({ filterValue })
            onClosePalette()
          }
        })
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commands])

  return (
    <Flex gap="small" direction="column" fullWidth>
      <Heading2 variant="small">{group}</Heading2>

      {commands
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((command) => (
          <button
            key={command.id}
            onClick={() => {
              command.action({ filterValue })
              onClosePalette()
            }}
            className={styles.commandButton}
            aria-label={command.name}
          >
            <Flex alignItems="center" gap="small" justifyContent="space-between" fullWidth>
              <Flex alignItems="center" gap="small">
                {command.icon}
                <Paragraph>{command.name}</Paragraph>
              </Flex>
              <Flex alignItems="center" gap="small">
                {command.shortcut && (
                  <Badge className={styles.badge}>
                    {isMacOS ? t('cmd') : t('ctrl')}+{t('shift')}+
                    {command.shortcut.toLocaleUpperCase()}
                  </Badge>
                )}
                {command.actionText && filterValue && (
                  <Flex className={styles.linkButton} gap="tiny" alignItems="center">
                    {command.actionText(filterValue)}
                    {command.actionIcon}
                  </Flex>
                )}
                {(!command.actionText || !filterValue) && (
                  <div className={styles.linkButton}>
                    <ArrowRightIcon fontSize={24} />
                  </div>
                )}
              </Flex>
            </Flex>
          </button>
        ))}
    </Flex>
  )
}
