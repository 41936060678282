'use client'

import { useEffect, useState } from 'react'

import { List, X } from '@phosphor-icons/react'
import { Button } from '@sikt/sds-button'
import { usePathname } from 'next/navigation'
import { useSession } from 'next-auth/react'
import { useTranslations } from 'next-intl'

import { Menu } from './Menu/Menu'
import { Personas } from '../../features/login/Personas/Personas'
import { UserInfo } from '../../features/login/UserInfo/UserInfo'
import { Flex } from '@/components/Flex/Flex'
import { Link } from '@/components/Link'
import { SignOutButton } from '@/features/login/Signoutbutton/SignOutButton'
import { FeatureFlag } from '@/utils/featureFlags/FeatureFlag'

import styles from './header.module.css'

export const Header = () => {
  const tHeader = useTranslations('login.Header')
  const { status } = useSession()
  const [menuExpanded, setMenuExpanded] = useState(false)

  const path = usePathname()

  useEffect(() => {
    setMenuExpanded(false)
  }, [path])

  return (
    <header className={styles.headerWrapper} role="banner">
      <Flex className={styles.header} justifyContent="space-between" alignItems="center">
        <Flex alignItems="center">
          {status === 'authenticated' && (
            <>
              <div className={styles.menu}>
                <Button
                  variant="transparent"
                  iconVariant="left"
                  icon={menuExpanded ? <X /> : <List />}
                  onClick={() => {
                    setMenuExpanded((prev) => !prev)
                  }}
                  className={styles.menu}
                >
                  <span className={styles.fixedWidthText}>
                    {menuExpanded ? tHeader('close') : tHeader('meny')}
                  </span>
                </Button>
              </div>
              <span className={styles.divider} />
            </>
          )}
          <Link className={styles.logo} aria-current="page" href="/">
            <Flex alignItems="center">
              <span>{tHeader('logo')}</span>
            </Flex>
          </Link>
        </Flex>
        {status === 'authenticated' && <UserInfo />}
      </Flex>
      {menuExpanded && (
        <>
          <div
            className={styles.overlay}
            role="none"
            onClick={() => {
              setMenuExpanded(false)
            }}
          />
          <nav
            aria-label={tHeader('menuDropdown')}
            role="navigation"
            className={styles.dropdownMenu}
          >
            <Menu />
            <Flex direction="column" gap="medium">
              <FeatureFlag
                flag="personas"
                environmentsOverride={{
                  inDevelopment: true,
                  inReview: true,
                  inTest: true,
                }}
                doNotFetchFlags
              >
                <Personas />
              </FeatureFlag>
              {status !== 'loading' ? <SignOutButton /> : null}
            </Flex>
          </nav>
        </>
      )}
    </header>
  )
}
