'use client'

export function useEnvironment() {
  const hostname = typeof window !== 'undefined' ? window.location.hostname : ''

  const reviewPattern = /^opptak-admissio-soknadsbehandling-review[^.]+\.paas2\.uninett\.no$/
  const testPattern = /^test-fsadmin.sikt.no$/
  const productionPattern = /^opptak-admissio-soknadsbehandling-prod[^.]+\.paas2\.uninett\.no$/
  const productionRealHostnamePattern = /^fsadmin.sikt.no$/

  return {
    isProduction: productionPattern.test(hostname) || productionRealHostnamePattern.test(hostname),
    isTest: testPattern.test(hostname),
    isReview: reviewPattern.test(hostname),
    isDevelopment:
      !productionRealHostnamePattern.test(hostname) &&
      !productionPattern.test(hostname) &&
      !testPattern.test(hostname) &&
      !reviewPattern.test(hostname),
  }
}
