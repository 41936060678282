'use client'

import { Button, ButtonProps } from '@sikt/sds-button'
import { SignOutIcon } from '@sikt/sds-icons'
import { signOut, useSession } from 'next-auth/react'
import { useTranslations } from 'next-intl'

import styles from './SignOutButton.module.css'

export const SignOutButton = ({ ...rest }: Omit<ButtonProps, 'children'>) => {
  const { data: session, status } = useSession()
  const t = useTranslations('login.SignOutButton')

  return status !== 'loading' ? (
    <Button
      onClick={() =>
        void signOut({
          callbackUrl: `https://auth.dataporten.no/openid/endsession?post_logout_redirect_uri=${window.location.origin}&id_token_hint=${session?.idToken}`,
        })
      }
      className={styles.signOutButton}
      icon={<SignOutIcon />}
      {...rest}
    >
      {t('logout')}
    </Button>
  ) : null
}
