import { useEffect } from 'react'

import { fetchFeatureFlags } from './unleashServerAction'
import { EnvironmentsEnableOverride, flagsStore, useTypedFlag } from './useTypedFlag'
import { useTypedRouter } from '@/hooks/useTypedRouter/useTypedRouter'
import { FeatureName } from '@/types/unleash'

export interface FeatureFlagBase {
  flag: FeatureName
  environmentsOverride?: EnvironmentsEnableOverride
}

interface FeatureFlagProps extends FeatureFlagBase {
  children: React.ReactNode
  redirectIfNotEnabled?: boolean
  doNotFetchFlags?: boolean
}

export function FeatureFlag({
  flag,
  children,
  environmentsOverride,
  redirectIfNotEnabled,
  doNotFetchFlags = false,
}: FeatureFlagProps) {
  const { isEnabled } = useTypedFlag({
    flag: flag,
    environmentsOverride: { ...environmentsOverride },
  })
  const { setFlagStore } = flagsStore()
  const router = useTypedRouter()

  useEffect(() => {
    async function getFetchFeatureFlags() {
      const flags = await fetchFeatureFlags()
      setFlagStore(flags)
    }

    if (!doNotFetchFlags) {
      getFetchFeatureFlags().catch((error) => {
        console.error('Error fetching feature flags', error)
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!isEnabled && redirectIfNotEnabled) {
      router.replace('/unauthorized')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEnabled])

  if (isEnabled) {
    return children
  }
}
