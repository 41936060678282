'use client'

import { IToggle } from '@unleash/nextjs'
import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

import { useEnvironment } from '../../hooks/useEnvironment'
import { FeatureName } from '@/types/unleash'

interface FlagsStore {
  flags: IToggle[] | undefined
}

interface FlagsStoreActions {
  setFlagStore: (flags: IToggle[]) => void
}

export const flagsStore = create(
  immer<FlagsStore & FlagsStoreActions>((set) => ({
    flags: undefined,
    setFlagStore: (flags) => {
      set((state: FlagsStore) => {
        state.flags = flags
      })
    },
  })),
)

interface UseTypedFlagProps {
  flag: FeatureName
  environmentsOverride: EnvironmentsEnableOverride
}

export interface EnvironmentsEnableOverride {
  inTest?: boolean
  inReview?: boolean
  inDevelopment?: boolean
}

export const useTypedFlag = ({
  flag,
  environmentsOverride: { inTest = false, inReview = true, inDevelopment = true },
}: UseTypedFlagProps): { isEnabled: boolean | undefined } => {
  const { isDevelopment, isReview, isTest } = useEnvironment()
  const { flags } = flagsStore()

  const isEnabled = flags?.some((f) => f.name === flag && f.enabled)

  let isEnvironmentOverrideEnabled: boolean | undefined

  switch (true) {
    case isTest:
      isEnvironmentOverrideEnabled = inTest
      break
    case isReview:
      isEnvironmentOverrideEnabled = inReview
      break
    case isDevelopment:
      isEnvironmentOverrideEnabled = inDevelopment
      break
    default:
      isEnvironmentOverrideEnabled = undefined
  }

  return {
    isEnabled: isEnvironmentOverrideEnabled ?? isEnabled,
  }
}
