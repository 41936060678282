import { MagnifyingGlassIcon, UserCircleIcon } from '@sikt/sds-icons'
import { FuseResult } from 'fuse.js'
import { useTranslations } from 'next-intl'

import { useTypedRouter } from '@/hooks/useTypedRouter/useTypedRouter'
import { FeatureFlagBase } from '@/utils/featureFlags/FeatureFlag'

export interface CommandGroup {
  heading: string
  id: string
  commands: Command[]
}

export interface CommandActionArgs {
  filterValue: string
}

export interface Command {
  name: string
  id: string
  action: (args: CommandActionArgs) => void
  actionText?: (filterValue: string) => string
  actionIcon?: React.ReactNode
  icon: React.ReactNode
  alwaysShow?: boolean
  shortcut?: string
  fuseResult?: FuseResult<Command>
  featureFlag?: FeatureFlagBase
}

export function useCommands() {
  const typedRouter = useTypedRouter()
  const t = useTranslations('components.CommandPalette')

  const commandGroups: CommandGroup[] = [
    {
      heading: t('CommandGroups.person.heading'),
      id: 'person',
      commands: [
        {
          name: t('CommandGroups.person.commands.personSearch.name'),
          id: 'personSearch',
          action: ({ filterValue }) => {
            typedRouter.push({ pathname: '/person', searchParams: { searchinput: filterValue } })
          },
          actionText: (filterValue) => `${t('searchFor')}: ${filterValue}`,
          actionIcon: <MagnifyingGlassIcon fontSize={24} />,
          icon: <UserCircleIcon fontSize={32} />,
          alwaysShow: true,
          shortcut: 'p',
        },
        {
          name: t('CommandGroups.person.commands.groupSearch.name'),
          id: 'groupSearch',
          action: () => {
            typedRouter.push('/person/gruppe')
          },
          icon: <UserCircleIcon fontSize={32} />,
          shortcut: 'g',
        },
      ],
    },
    // {
    //   heading: t('CommandGroups.searchAndAdmission.heading'),
    //   id: 'searchAndAdmission',
    //   commands: [
    //     {
    //       name: t('CommandGroups.searchAndAdmission.caseHandling.name'),
    //       id: 'caseHandling',
    //       action: () => {
    //         typedRouter.push('/soknadsbehandling')
    //       },
    //       icon: <ListChecks size={32} />,
    //       featureFlag: {
    //         flag: 'soknadsbehandling',
    //         environmentsOverride: {
    //           inDevelopment: true,
    //           inReview: true,
    //           inTest: true,
    //         },
    //       },
    //     },
    //     {
    //       name: t('CommandGroups.searchAndAdmission.createLocalAdmission.name'),
    //       id: 'createLocalAdmission',
    //       action: () => {
    //         typedRouter.push({ pathname: '/opptak/opprett/[type]', params: { type: 'lokalt' } })
    //       },
    //       icon: <ArrowRightIcon fontSize={32} />,
    //     },
    //     {
    //       name: t('CommandGroups.searchAndAdmission.admission.name'),
    //       id: 'admission',
    //       action: () => {
    //         typedRouter.push({ pathname: '/opptak' })
    //       },
    //       icon: <Student size={32} />,
    //     },
    //     {
    //       name: t('CommandGroups.searchAndAdmission.rules.name'),
    //       id: 'rules',
    //       action: () => {
    //         typedRouter.push('/regelverk')
    //       },
    //       icon: <Scales size={32} />,
    //     },
    //   ],
    // },
  ]

  return { commandGroups }
}
