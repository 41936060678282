'use client'

import { useTranslations } from 'next-intl'

import Flex from '../Flex/Flex'
import { Link } from '../Link'

export function FooterContent() {
  const t = useTranslations('common')
  return (
    <Flex direction="column" alignItems="flex-end" flexGrow={1}>
      <Link href="/om/endringslogg">{t('Footer.changeLog')}</Link>
    </Flex>
  )
}
