'use client'

import { Select } from '@sikt/sds-select'

import { UserInformation, useUserContext } from '@/lib/auth/userContext'

export const PERSONAS: UserInformation[] = [
  {
    fornavn: 'Borghild',
    etternavn: 'Aga',
    beskrivelse: 'Saksbehandler',
    identifikator: '27616390061',
    rollekode: 'SUPERBRUKER',
    navn: 'Utviklingsorganisasjonen',
    organisasjonsnummer: '123456789',
  },
  {
    fornavn: 'UIO KORT',
    etternavn: 'DOMPAP',
    beskrivelse: 'Superbruker',
    identifikator: '11906698511',
    rollekode: 'SUPERBRUKER',
    navn: 'Universitetet i Oslo',
    organisasjonsnummer: '971035854',
  },
  {
    fornavn: 'UIO BESKJEDEN',
    etternavn: 'ROSIN',
    beskrivelse: 'Søknadsbehandler',
    identifikator: '23917297398',
    rollekode: 'SØKNADSBEHANDLER',
    navn: 'Universitetet i Oslo',
    organisasjonsnummer: '971035854',
  },
  {
    fornavn: 'UIO KOMPLEKS',
    etternavn: 'CORNER',
    beskrivelse: 'Opptaksleder',
    identifikator: '17856798216',
    rollekode: 'OPPTAKSLEDER',
    navn: 'Universitetet i Oslo',
    organisasjonsnummer: '971035854',
  },
  {
    fornavn: 'UIT KORT',
    etternavn: 'DOMPAP',
    beskrivelse: 'Superbruker',
    identifikator: '11906698511',
    rollekode: 'SUPERBRUKER',
    navn: 'Universitetet i Tromsø',
    organisasjonsnummer: '970 422 528',
  },
  {
    fornavn: 'UIT BESKJEDEN',
    etternavn: 'ROSIN',
    beskrivelse: 'Søknadsbehandler',
    identifikator: '23917297398',
    rollekode: 'SØKNADSBEHANDLER',
    navn: 'Universitetet i Tromsø',
    organisasjonsnummer: '970 422 528',
  },
  {
    fornavn: 'UIT KOMPLEKS',
    etternavn: 'CORNER',
    beskrivelse: 'Opptaksleder',
    identifikator: '17856798216',
    rollekode: 'OPPTAKSLEDER',
    navn: 'Universitetet i Tromsø',
    organisasjonsnummer: '970 422 528',
  },
  {
    fornavn: 'Test',
    etternavn: 'Utvikler',
    beskrivelse: 'Testutvikler',
    identifikator: '07546490143',
    rollekode: 'TESTBRUKER',
    navn: 'Utviklingsorganisasjonen',
    organisasjonsnummer: '123456789',
  },
]

const personaId = (persona: UserInformation | undefined) =>
  persona ? `${persona.identifikator}:${persona.navn}` : ''
/**
 * Component for selecting a user persona, used for development purposes only.
 */
export function Personas() {
  const { userInfo, setUserInformation } = useUserContext()

  const personas_options = [
    { label: 'Ingen', value: '' },
    ...PERSONAS.map((persona) => ({
      label: `${persona.fornavn} ${persona.etternavn}`,
      value: personaId(persona),
    })),
  ]

  return (
    <Select
      label="Velg bruker"
      value={personaId(userInfo)}
      options={personas_options}
      onChange={(e) => {
        setUserInformation(PERSONAS.find((persona) => personaId(persona) === e.target.value))
      }}
    />
  )
}
